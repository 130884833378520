import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import api from '../../services/api';
import PaymentModal from './account_modals/PaymentModal';
import CancelSubscriptionModal from './account_modals/CancelSubscriptionModal';
import SubscriptionModal from './account_modals/SubscriptionModal';
import SubscriptionCanceledModal from './account_modals/SubscriptionCanceledModal';
import AccountUpdatedModal from './account_modals/AccountUpdatedModal';
import ExpiredModal from './account_modals/ExpiredModal';
import CCExpiredModal from './account_modals/CCExpiredModal';
import TrialExpiredModal from './account_modals/TrialExpiredModal';
import PaymentMethodUpdatedModal from './account_modals/PaymentMethodUpdatedModal';
import PersonalDetails from './screens/PersonalDetails';
import Notifications from './screens/Notifications';
import BillingHistory from './screens/BillingHistory';

export default function Account({
  tab, modal, action, user, account, subscription, plans, paymentMethod, stripeKey, isExpired, isAdmin, userRoles, payments, editPasswordPath, visa, hasDynamicAccess, two_factor_enabled,
}) {
  const plan = plans.find((p) => p.id === (subscription || {}).plan_id) || {};
  const [currentTab, setCurrentTab] = useState(tab || 'personalDetails');
  const [data, setData] = useState({
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    email: user.email || '',
    caseLawEmailEnabled: user.settings.email_preferences.send_case_law_email,
    monthlyUserUpdateEnabled: user.settings.email_preferences.monthly_updates,
    dynamicCiteListsEmailEnabled: user.settings.email_preferences.send_dynamic_cite_list_email,
    selectedRoles: user.user_roles || [],
    subscriptionCanceledAt: null,
  });
  const [updatePaymentStatus, setUpdatePaymentStatus] = useState('');
  const [accountUpdatedModalVisible, setAccountUpdatedModalVisible] = useState(false);
  const [cancelSubscriptionModalVisible, setCancelSubscriptionModalVisible] = useState(false);
  const [subscriptionCanceledModalVisible, setSubscriptionCanceledModalVisible] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(modal === 'subscription');
  const [expiredTrialModalVisible, setExpiredTrialModalVisible] = useState((plan.plan_type === 'trial' && isExpired && !action) || false);
  const [expiredModalVisible, setExpiredModalVisible] = useState((plan.plan_type !== 'credit_card' && plan.plan_type !== 'trial' && plan.plan_type !== 'free' && isExpired && !user.academy_subscription && !action && !modal) || false);
  const [expiredCCModalVisible, setExpiredCCModalVisible] = useState((plan.plan_type === 'credit_card' && isExpired && !action) || false);
  const [paymentMethodModalVisible, setPaymentMethodModalVisible] = useState(modal === 'payment_method');
  const [paymentMethodUpdatedModalVisible, setPaymentMethodUpdatedModalVisible] = useState(false);
  const [subscriptionAction, setSubscriptionAction] = useState(action || 'eda');
  const [isSubscription, setIsSubscription] = useState(modal === 'payment_method');

  const updateUserData = () => {
    api.account.user.put({
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        user_roles: data.selectedRoles.map((role) => role.id),
      },
    })
      .then(() => setAccountUpdatedModalVisible(!accountUpdatedModalVisible))
      .catch(() => window.location.reload(true));
  };

  const handleChange = (e) => setData({ ...data, [e.target.id]: e.target.value });

  const selectRole = (role) => {
    const roles = data.selectedRoles;
    const _roleIndex = roles.findIndex((selectedRole) => selectedRole.id === role.id);

    if (_roleIndex === -1) roles.push(role);
    else roles.splice(_roleIndex, 1);

    setData({ ...data, selectedRoles: roles });
  };

  const cancelSubscription = () => {
    const subscription_id = subscriptionAction === 'eda' ? subscription.id : user.academy_subscription.id;
    setCancelSubscriptionModalVisible(false);
    api.cancel_subscription(subscription_id).then(({ subscription: sub }) => {
      setSubscriptionCanceledModalVisible(true);
      setData({ ...data, subscriptionCanceledAt: sub.effective_to });
    });
  };

  const updatePaymentMethod = (token, promo_code, interval) => {
    setUpdatePaymentStatus('subscribing');
    api.subscribe({
      plan_name: account.account_type, stripe_token: token, promo_code, interval,
    }).then(() => {
      setUpdatePaymentStatus('success');
      setPaymentMethodModalVisible(false);
      setSubscriptionModalVisible(false);
      setPaymentMethodUpdatedModalVisible(true);
      window.location.href = '/account/edit?tab=billingInfo';
    });
  };

  const academyPaymentMethod = (token, promo_code, interval) => {
    const { academy_subscription } = user;
    if (academy_subscription) {
      if (!academy_subscription || !academy_subscription.stripe_cancelled || !token) return;
    }

    setUpdatePaymentStatus('subscribing');
    api.academy_subscribe(token, promo_code, interval).then(() => {
      setUpdatePaymentStatus('success');
      setPaymentMethodModalVisible(false);
      setSubscriptionModalVisible(false);
      setPaymentMethodUpdatedModalVisible(true);
      window.location.href = '/account/edit?tab=billingInfo';
    });
  };

  return (
    <div className="react-account">
      <div className="left">
        <div className="account-settings">Account Settings</div>
        <div className="account-categories container-left">
          <div
            className={`category ${currentTab === 'personalDetails' ? ' selected' : ''}`}
            onClick={() => setCurrentTab('personalDetails')}
          >
            Personal Detail
          </div>
          <div
            className={`category ${currentTab === 'notifications' ? ' selected' : ''}`}
            onClick={() => setCurrentTab('notifications')}
          >
            Notifications
          </div>
          <div
            className={`category ${currentTab === 'billingInfo' ? ' selected' : ''}`}
            onClick={() => setCurrentTab('billingInfo')}
          >
            Billing Info
          </div>
        </div>
        { currentTab === 'personalDetails'
            && (
            <PersonalDetails
              editPasswordPath={editPasswordPath}
              user={data}
              userRoles={userRoles}
              handleChange={handleChange}
              updateUserData={updateUserData}
              selectRole={selectRole}
              two_factor_enabled={two_factor_enabled}
            />
            )}
        { currentTab === 'notifications'
            && (
            <Notifications
              caseLawEmailEnabled={data.caseLawEmailEnabled}
              monthlyUserUpdateEnabled={data.monthlyUserUpdateEnabled}
              dynamicCiteListsEmailEnabled={data.dynamicCiteListsEmailEnabled}
              toggleDynamicCiteListEmail={() => setData({ ...data, dynamicCiteListsEmailEnabled: !data.dynamicCiteListsEmailEnabled })}
              toggleMonthlyUserUpdate={() => setData({ ...data, monthlyUserUpdateEnabled: !data.monthlyUserUpdateEnabled })}
              toggleCaseLawEmail={() => setData({ ...data, caseLawEmailEnabled: !data.caseLawEmailEnabled })}
              account_type={account.account_type}
              isAdmin={isAdmin}
              hasDynamicAccess={hasDynamicAccess}
            />
            )}
        { currentTab === 'billingInfo'
            && (
            <BillingHistory
              plans={plans}
              subscription={subscription}
              account={account}
              payments={payments}
              user={user}
              paymentMethod={paymentMethod}
              visa={visa}
              setPaymentMethodModalVisible={setPaymentMethodModalVisible}
              setSubscriptionModalVisible={setSubscriptionModalVisible}
              setSubscriptionAction={setSubscriptionAction}
              setIsSubscription={setIsSubscription}
              isExpired={isExpired}
            />
            )}
      </div>

      { accountUpdatedModalVisible
          && <AccountUpdatedModal toggleAccountUpdatedModalVisible={() => setAccountUpdatedModalVisible(!accountUpdatedModalVisible)} /> }
      { cancelSubscriptionModalVisible
          && <CancelSubscriptionModal cancelSubscription={cancelSubscription} closeModal={() => setCancelSubscriptionModalVisible(false)} /> }
      { subscriptionCanceledModalVisible
          && <SubscriptionCanceledModal subscriptionCanceledAt={data.subscriptionCanceledAt} closeModal={() => setSubscriptionCanceledModalVisible(false)} /> }
      { expiredModalVisible
          && <ExpiredModal account={account} plan={plan} closeModal={() => setExpiredModalVisible(false)} /> }
      { paymentMethodUpdatedModalVisible
          && <PaymentMethodUpdatedModal /> }
      { expiredCCModalVisible
          && (
          <CCExpiredModal
            account={account}
            closeModal={() => setExpiredCCModalVisible(false)}
            togglePaymentMethodModal={() => {
              setExpiredCCModalVisible(false);
              setPaymentMethodModalVisible(true);
              setIsSubscription(true);
            }}
          />
          )}
      { expiredTrialModalVisible
          && (
          <TrialExpiredModal
            closeModal={() => setExpiredTrialModalVisible(false)}
            togglePaymentMethodModal={() => {
              setExpiredTrialModalVisible(false);
              if (paymentMethod) {
                setSubscriptionModalVisible(true);
              } else {
                setPaymentMethodModalVisible(true);
              }
              setIsSubscription(true);
            }}
          />
          )}
      { paymentMethodModalVisible
          && (
          <StripeProvider apiKey={stripeKey}>
            <Elements fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Rubik' }]}>
              <PaymentModal
                subscriptionAction={subscriptionAction}
                closeModal={() => setPaymentMethodModalVisible(false)}
                status={updatePaymentStatus}
                paymentMethod={paymentMethod}
                onStatusUpdate={(status) => setUpdatePaymentStatus(status)}
                isSubscription={isSubscription}
              />
            </Elements>
          </StripeProvider>
          )}
      { subscriptionModalVisible
          && (
          <SubscriptionModal
            plans={plans}
            plan={plan}
            account_type={account.account_type}
            subscriptionAction={subscriptionAction}
            onSubmit={subscriptionAction === 'eda' ? updatePaymentMethod : academyPaymentMethod}
            closeModal={() => setSubscriptionModalVisible(false)}
            status={updatePaymentStatus}
            paymentMethod={paymentMethod}
          />
          )}
    </div>
  );
}

Account.propTypes = {
  editPasswordPath: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  userRoles: PropTypes.array.isRequired,
  subscription: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
  payments: PropTypes.array.isRequired,
};
